import {
  BrowserRouter,
  Navigate,
  Route,
  Outlet,
  Routes,
  useLocation,
} from "react-router-dom";

import { useAuth } from "./context/auth-context";

import AccountPage from "./pages/account-page";
import ForgetPasswordPage from "./pages/auth/forget-password-page";
import LoginPage from "./pages/auth/login-page";
import ResetPasswordPage from "./pages/auth/reset-password-page";
import ValidateEmailPage from "./pages/auth/validate-email-page";
import VerifyAccountPage from "./pages/auth/verify-account-page";
import CookiePolicyPage from "./pages/cookie-policy-page";
import FilePage from "./pages/file-page";
import LogsPage from "./pages/logs-page";
import MonitoringPage from "./pages/monitoring-page";
import NotFoundPage from "./pages/not-found-page";
import PatientPage from "./pages/patient-page";
import PatientsPage from "./pages/patients-page";
import PrescribersPage from "./pages/prescribers-page";
import StatsPage from "./pages/stats-page";
import UsersPage from "./pages/users-page";

function App() {
  let { user } = useAuth();

  const RequireAuth = ({ allowedRoles }) => {
    const { user } = useAuth();
    const location = useLocation();

    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!allowedRoles.includes(user.role)) {
      return <Navigate to="/not-found" replace />;
    }

    return <Outlet />;
  };

  const AuthRoute = ({ children }) => {
    return user ? <Navigate replace to="/" /> : children;
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Routes publiques */}
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgetPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          path="/reset-password/:id"
          element={
            <AuthRoute>
              <ResetPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          path="/validate-email"
          element={
            <AuthRoute>
              <ValidateEmailPage />
            </AuthRoute>
          }
        />
        <Route
          path="/verify-account/:id"
          element={
            <AuthRoute>
              <VerifyAccountPage />
            </AuthRoute>
          }
        />
        <Route
          path="/politique-cookies"
          element={
            <AuthRoute>
              <CookiePolicyPage />
            </AuthRoute>
          }
        />
        {/* Routes privées */}
        <Route
          path="/"
          element={
            <RequireAuth
              allowedRoles={[
                "admin",
                "prescriber",
                "commercial",
                "director",
                "supervisor",
              ]}
            />
          }
        >
          <Route path="/" element={<PatientsPage />} />
          <Route path="/mon-compte" element={<AccountPage />} />
          <Route path="/patients" element={<PatientsPage />} />
          <Route path="/patients/:id" element={<PatientPage />} />
          <Route path="/file/:type/:id" element={<FilePage />} />
        </Route>

        <Route
          path="/utilisateurs"
          element={<RequireAuth allowedRoles={["admin"]} />}
        >
          <Route path="/utilisateurs" element={<UsersPage />} />
        </Route>
        <Route
          path="/prescripteurs"
          element={<RequireAuth allowedRoles={["admin"]} />}
        >
          <Route path="/prescripteurs" element={<PrescribersPage />} />
        </Route>
        <Route
          path="/monitoring"
          element={<RequireAuth allowedRoles={["admin"]} />}
        >
          <Route path="/monitoring" element={<MonitoringPage />} />
        </Route>
        <Route
          path="/statistiques"
          element={<RequireAuth allowedRoles={["admin"]} />}
        >
          <Route path="/statistiques" element={<StatsPage />} />
        </Route>
        <Route path="/logs" element={<RequireAuth allowedRoles={["admin"]} />}>
          <Route path="/logs" element={<LogsPage />} />
        </Route>

        <Route
          path="*"
          element={user ? <NotFoundPage /> : <Navigate replace to="/login" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
