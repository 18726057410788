import moment from "moment";

export const checkSignable = ({
  code,
  finess,
  finessOther,
  socialReason,
  startDate,
  endDate,
  catheter,
}) => {
  let isSignable = false;
  let isFinessValid = false;

  if (
    [
      "ST",
      "STD",
      "STMT",
      "SP ST",
      "SP STD",
      "SP AT",
      "CANL",
      "CADXG6",
      "CADXG6S",
    ].includes(code)
  ) {
    isSignable = true;
  } else if (
    [
      "AT",
      "BF MED",
      "BF MEDG4",
      "SP BF",
      "BF CAMAPS",
      "BF TANDEM",
      "AT MED",
      "AT YPSO",
      "AT TANDEM",
      "AT BIQ",
      "AT MED740G",
      "BF DIABELOOP",
      "STMT300",
    ].includes(code) /*&&
    typeof catheter === "string" &&
    catheter.trim() !== ""*/
  ) {
    isSignable = true;
  }
  if (typeof finess === "string" && finess.trim() !== "") {
    isFinessValid = true;
  } else if (
    typeof finessOther === "string" &&
    finessOther.trim() !== "" &&
    typeof socialReason === "string" &&
    socialReason.trim() !== ""
  ) {
    isFinessValid = true;
  }
  return (
    isSignable &&
    isFinessValid &&
    startDate &&
    moment(startDate).isValid() &&
    endDate &&
    moment(endDate).isValid() &&
    moment(endDate).isSameOrAfter(startDate)
  );
};

export const getLabelPrescription = (plan) => {
  switch (plan) {
    case "ST":
      return "OMNIPOD";
    case "STD":
      return "OMNIPOD DASH";
    case "STMT":
      return "MEDTRUM TOUCHCARE";
    case "AT":
      return "POMPE A INSULINE";
    case "BF MED":
      return "MEDTRONIC 780G/GS3";
    case "BF MEDG4":
      return "MEDTRONIC 780G/G4S";
    case "CANL":
      return "CAPTEUR ENLITE";
    case "CADXG6":
      return "CAPTEUR DEXCOM G6";
    case "CADXG6S":
      return "CAPTEUR DEXCOM G6";
    case "SP BF":
      return "SUSPENSION MEDTRONIC 780G";
    case "SP ST":
      return "SUSPENSION OMNIPOD";
    case "SP STD":
      return "SUSPENSION OMNIPOD DASH";
    case "SP AT":
      return "SUSPENSION POMPE A INSULINE";
    case "BF CAMAPS":
      return "BF YPSOMED CAMAPS";
    case "BF TANDEM":
      return "TANDEM";
    case "AT MED":
      return "AT MED";
    case "AT YPSO":
      return "AT YPSOMED";
    case "AT TANDEM":
      return "AT TANDEM";
    case "AT BIQ":
      return "AT BIQ";
    case "AT MED740G":
      return "AT MED740G";
    case "BF DIABELOOP":
      return "BF DIABELOOP";
    case "STMT300":
      return "STMT300";
    default:
      return null;
  }
};

export const contractSettings = (plan) => {
  let settings = {
    label: getLabelPrescription(plan),
    items: {
      careSet: { display: false, default: 1 },
      tegaderm6X7: { display: false, default: 1 },
      tegaderm9X10: { display: false, default: 1 },
      catheter: { display: false, default: "" },
      autocontrol: { display: false, default: false },
    },
  };
  settings.enabled = settings.label !== null;
  switch (plan) {
    case "ST":
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      break;
    case "STD":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      break;
    case "STMT":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      break;
    case "AT":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF MED":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF MEDG4":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "CANL":
      settings.items.autocontrol.display = true;
      break;
    case "CADXG6":
      settings.items.autocontrol.display = true;
      break;
    case "CADXG6S":
      settings.items.autocontrol.display = true;
      break;
    case "SP BF":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "SP ST":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      break;
    case "SP STD":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      break;
    case "SP AT":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      break;
    case "BF CAMAPS":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF TANDEM":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT MED":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT YPSO":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT TANDEM":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT BIQ":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT MED740G":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF DIABELOOP":
      //settings.items.catheter.display = true;
      break;
    case "STMT300":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm9X10.display = true;
      //settings.items.catheter.display = true;
      break;
    default:
      return settings;
  }
  return settings;
};
